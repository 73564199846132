import { footerElements } from '../utils/footerElements';

const Footer = () => {
   const renderFooter = () => {
      return footerElements.map(({ title, links }, i) => (
         <div className="footer-el" key={i}>
            <span className="title">{title}</span>
            <ul>
               {Object.keys(links).map((key, index) => (
                  <li key={index}>
                     {key}:&nbsp;&nbsp; {links[key]}
                  </li>
               ))}
            </ul>
         </div>
      ));
   };

   return <footer className="footer">{renderFooter()}</footer>;
};

export default Footer;
