import Row from '../Components/Row';

import { photosList } from '../utils/photosList';
import video from '../assets/videos/video.mp4';

const Main = () => {
   const renderRows = (upper) => {
      const filteredImages = upper
         ? photosList.filter(({ section }) => section === 1)
         : photosList.filter(({ section }) => section === 2);

      return filteredImages.map(({ images }, index) => (
         <Row
            images={images}
            key={index}
            pos={upper ? `upper-${index}` : `lower-${index}`}
         />
      ));
   };

   return (
      <main className="container">
         {renderRows(1)}
         <div className="video-container">
            <video autoplay controls>
               <source src={video} type="video/mp4" />
               Il tuo browser non supporta la riproduzione di video.
            </video>
         </div>
         {renderRows()}
      </main>
   );
};

export default Main;
