const useFadeOnScroll = (selectorToWatch, classToAdd, removeClass = false) => {
   // Add or remove css class when user visually intersect an element in the page
   // Add 'selectorToWatch' className to the elements you want to watch
   // Add css to classToAdd when element is intersected

   let observer = new IntersectionObserver(
      (items) => {
         items.forEach(({ isIntersecting, target }) => {
            if (isIntersecting) target.classList.add(classToAdd);
            if (!isIntersecting && removeClass)
               target.classList.remove(classToAdd);
         });
      },
      { threshold: 0.25 }
   );

   document
      .querySelectorAll(selectorToWatch) // Remember the . in hook call
      .forEach((element) => observer.observe(element));
};

export default useFadeOnScroll;
