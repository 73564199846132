import { useEffect, useState } from 'react';

import Home from './pages/Home';
import SplashLogo from './pages/Splash';

import './assets/scss/index.scss';

function App() {
   const [showHome, setShowHome] = useState(false);

   useEffect(() => {
      setTimeout(() => setShowHome(true), 3500);
   }, []);

   if (!showHome) return <SplashLogo />;

   return <Home />;
}

export default App;
