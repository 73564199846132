import { useState } from 'react';

import useFadeOnScroll from '../hooks/useFadeOnScroll';

import placeHolderImage from '../assets/images/Logo-asino.jpg';
import '../assets/scss/index.scss';

const Row = ({ images, pos }) => {
   useFadeOnScroll('.row', 'row-in-page', false);
   const [imageIsLoaded, setImageIsLoaded] = useState(false);

   const setImageIsLoadedHandler = () => setImageIsLoaded(true);

   return (
      <div className={`row row-${pos}`}>
         {images.map((photo, i) => {
            return (
               <div key={i} className={`photo photo-${i}`}>
                  <img
                     src={imageIsLoaded ? photo : placeHolderImage}
                     onLoad={setImageIsLoadedHandler}
                     alt={`NEOnativo-p${i}`}
                  />
               </div>
            );
         })}
      </div>
   );
};
export default Row;
