import Main from '../Components/Main';
import Footer from '../Components/Footer';

import '../assets/scss/index.scss';

const Home = () => {
   return (
      <>
         <Main />
         <Footer />
      </>
   );
};

export default Home;
