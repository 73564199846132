import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export const footerElements = [
   {
      title: 'Shooting',
      links: {
         'ART DIRECTION': 'Paolo Lanzilotta & Mattia Carlotto',
         PHOTOGRAPHY: 'Davide Faedo',
         'ASSISTANT PHOTOGRAPHER': 'Eros Rossetto',
         MUA: 'Anna Costa',
         MODELS: 'Alice Tonellotto, Leonardo Losio',
         CASTING: 'Gregorio Mettifogo',
         'SET ASSISTANT': 'Filippo Portinari',
      },
   },
   {
      title: 'Video',
      links: {
         'ART DIRECTION': 'Paolo Lanzilotta & Mattia Carlotto',
         VIDEOMAKER: 'Pietro Vincenti',
      },
   },
   {
      title: 'Website',
      links: {
         DEVELOPER: (
            <a
               href="https://www.linkedin.com/in/davidedeleonardis/"
               target="_blank"
               rel="noreferrer"
            >
               <span>Davide De Leonardis</span>
               <FontAwesomeIcon icon="fa-solid fa-arrow-up-right-from-square" />
            </a>
         ),
      },
   },
];
