export const photosList = [
   {
      section: 1,
      images: [
         require('../assets/images/104NEOnativo0408-2 2.jpg'),
         require('../assets/images/104NEOnativo0408-2 2.jpg'),
      ],
   },
   {
      section: 1,
      images: [
         require('../assets/images/127DSCF0197-2.jpeg'),
         require('../assets/images/127DSCF0197-2.jpeg'),
         require('../assets/images/127DSCF0197-2.jpeg'),
      ],
   },
   {
      section: 1,
      images: [
         require('../assets/images/008NEOnativo0048-2 2.jpg'),
         require('../assets/images/008NEOnativo0048-2 2.jpg'),
         require('../assets/images/008NEOnativo0048-2 2.jpg'),
      ],
   },
   {
      section: 1,
      images: [
         require('../assets/images/254DSCF0759 3.jpeg'),
         require('../assets/images/254DSCF0759-2 2.jpeg'),
         require('../assets/images/254DSCF0759 3.jpeg'),
         require('../assets/images/254DSCF0759-2 2.jpeg'),
      ],
   },
   {
      section: 1,
      images: [
         require('../assets/images/174DSCF0384-3.jpeg'),
         require('../assets/images/174DSCF0384 3.jpeg'),
         require('../assets/images/174DSCF0384 3.jpeg'),
         require('../assets/images/174DSCF0384 3.jpeg'),
         require('../assets/images/174DSCF0384 3.jpeg'),
         require('../assets/images/174DSCF0384-3.jpeg'),
      ],
   },
   {
      section: 1,
      images: [
         require('../assets/images/147DSCF0279 3.jpeg'),
         require('../assets/images/147DSCF0279 3.jpeg'),
         require('../assets/images/147DSCF0279 3.jpeg'),
      ],
   },
   {
      section: 1,
      images: [
         require('../assets/images/033NEOnativo0176-2 2.jpg'),
         require('../assets/images/033NEOnativo0176-2 2.jpg'),
         require('../assets/images/033NEOnativo0176-2 2.jpg'),
         require('../assets/images/033NEOnativo0176-2 2.jpg'),
      ],
   },
   {
      section: 1,
      images: [
         require('../assets/images/072DSCF0090-2 2.jpg'),
         require('../assets/images/072DSCF0090-2 2.jpg'),
      ],
   },
   {
      section: 2,
      images: [
         require('../assets/images/231DSCF0660 4.jpeg'),
         require('../assets/images/231DSCF0660 4.jpeg'),
      ],
   },
   {
      section: 2,
      images: [
         require('../assets/images/154DSCF0302 2.jpeg'),
         require('../assets/images/154DSCF0302 2.jpeg'),
         require('../assets/images/154DSCF0302 2.jpeg'),
      ],
   },
   {
      section: 2,
      images: [
         require('../assets/images/247DSCF0737-3.jpeg'),
         require('../assets/images/247DSCF0737-3.jpeg'),
         require('../assets/images/247DSCF0737-3.jpeg'),
         require('../assets/images/247DSCF0737-3.jpeg'),
         require('../assets/images/247DSCF0737-3.jpeg'),
         require('../assets/images/247DSCF0737-3.jpeg'),
         require('../assets/images/247DSCF0737-3.jpeg'),
         require('../assets/images/247DSCF0737-3.jpeg'),
      ],
   },
   {
      section: 2,
      images: [
         require('../assets/images/157DSCF0313 3.jpeg'),
         require('../assets/images/157DSCF0313 3.jpeg'),
         require('../assets/images/157DSCF0313 3.jpeg'),
      ],
   },
   {
      section: 2,
      images: [
         require('../assets/images/247DSCF0737-3.jpeg'),
         require('../assets/images/247DSCF0737-3.jpeg'),
         require('../assets/images/247DSCF0737-3.jpeg'),
         require('../assets/images/247DSCF0737-3.jpeg'),
         require('../assets/images/247DSCF0737-3.jpeg'),
         require('../assets/images/247DSCF0737-3.jpeg'),
         require('../assets/images/247DSCF0737-3.jpeg'),
      ],
   },
   {
      section: 2,
      images: [require('../assets/images/139DSCF0245 3.jpeg')],
   },
];
