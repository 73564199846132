import logoAsino from '../assets/images/Logo-asino.jpg';
import '../assets/scss/index.scss';

const Splash = () => {
   return (
      <div className="container-splash">
         <div className="splash">
            <img src={logoAsino} alt="Logo asino" />
         </div>
      </div>
   );
};

export default Splash;
