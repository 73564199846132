import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App.jsx';

// Fontawesome
import { library } from '@fortawesome/fontawesome-svg-core';
import { faArrowUpRightFromSquare } from '@fortawesome/free-solid-svg-icons';
library.add(faArrowUpRightFromSquare);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
   <React.StrictMode>
      <App />
   </React.StrictMode>
);
